module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.10.0_gatsby-plugin-sharp@5.10.0_gatsby@5.10.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":820,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.10.0_gatsby@5.10.0_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GDF","short_name":"guiadelfrio","start_url":"/","background_color":"#fafafa","theme_color":"#ff483b","display":"minimal-ui","icon":"content/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"abac9ec088a28aaad5ddf058b8e6416f"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.10.0_gatsby@5.10.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.10.0_gatsby@5.10.0_react-dom@18.2.0_react-typography@0.16.23_react@18.2.0_typography@0.16.21/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.10.0_babel-plugin-styled-components@2.1.3_gatsby@5.10.0_rea_f76a43uvurtj2v357zvqwsslxu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.10.0_babel-eslint@10.1.0_eslint-import-resolver-typescript@3.5.5_eslint-plugin-jest@_pttofmqtjkqumjd5ax7jdjm6fa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
